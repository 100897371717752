import Vue from "vue";
import filtroOperacion from "@/components/filtroOperacion/filtroOperacion.vue";
import identificadorClientes from "@/components/IdentificadorClientes/identificadorClientes.vue";
import selectorAcciones from "@/components/selectorAcciones/selectorAcciones.vue";
import llamadaModule from "@/store/modules/llamadaModule";

export default Vue.extend({
  name: "LlamadaViewComponent",
  components: {
    filtroOperacion,
    identificadorClientes,
    selectorAcciones,
  },
  computed: {
    step(): number {
      return llamadaModule.step;
    },
    llamadaEnCurso(): boolean {
      return llamadaModule.llamadaEnCurso;
    },
  },
  methods: {
    accionesStepClick() {
      llamadaModule.SET_STEP(2);
    },
  },
});
