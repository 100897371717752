export enum ApiList {
  SistemaPagos = 0,
  Documentos = 1,
  Mail = 2,
  Geografia = 3,
  Intranet = 4,
  Bancos = 5,
  SEF = 6,
  HEH = 7,
  SMS = 8,
  SCB = 9,
  JmasChihuahua = 10,
  JmasDelicias = 11,
  JmasJuarez = 12,
  Gestopagos = 13,
  Ecogas = 14,
  GobiernoYucatan = 15,
  GobiernoChihuahua = 16,
  MunicipioJuarez = 17,
  SEND = 18,
  CorsManager = 19,
  UrlShortener = 20,
  SosFaq = 21,
  SasToken = 22,
}

export function getUrl(api: ApiList, mode: string): string {
  switch (api) {
    case ApiList.SistemaPagos:
      return mode == "production"
        ? "https://apis.pagosdigitales.com/SistemaPagos/V2/" //TODO: Cambiar Url a la de azure
        : "https://azure.dev.pagosdigitales.com/apis/sistemapagos/";
    case ApiList.Documentos:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/documentos/v0/"
        : "https://ws.kioskos.net/documentos/v1/";
    case ApiList.Mail:
      return mode == "production" ? "" : "";
    case ApiList.Geografia:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/geografia/v0/"
        : "https://ws.kioskos.net/geografia/v1/";
    case ApiList.Intranet:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/intranet/"
        : "https://azure.dev.pagosdigitales.com/apis/intranet/";
    case ApiList.Bancos:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/bancos/v0/"
        : "https://ws.kioskos.net/bancos/v1/";
    case ApiList.SEF:
      return mode == "production" ? "" : "";
    case ApiList.HEH:
      return mode == "production" ? "" : "";
    case ApiList.SMS:
      return mode == "production"
        ? "https://apis.pagosdigitales.com/SMS/V2/"
        : "https://dev.pagosdigitales.com/Apis/SMS/V1/";
    case ApiList.SCB:
      return mode == "production" ? "https://azure.pagosdigitales.com/apis/scb/v0/" : "https://ws.kioskos.net/scb/v1/";
    case ApiList.Gestopagos:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/gestopagos/v0/"
        : "https://ws.kioskos.net/gestopagos/v1/";
    case ApiList.MunicipioJuarez:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/municipiojuarez/v0/"
        : "https://dev.pagosdigitales.com/Apis/MunicipioJuarez/V1/"; //TODO: Obtener url de Azure
    case ApiList.GobiernoChihuahua:
      return mode == "production"
        ? "https://azure.pagosdigitales.com/apis/gobiernochihuahua/v0/"
        : "https://ws.kioskos.net/gobiernochihuahua/v1/";
    case ApiList.GobiernoYucatan:
      return mode == "production"
        ? "https://apis.pagosdigitales.com/GobiernoYucatan/V1/"
        : "https://dev.pagosdigitales.com/Apis/GobiernoYucatan/V1/";
    case ApiList.SEND:
      return mode == "production" ? "https://function-send.pagosdigitales.com/" : "";
    case ApiList.UrlShortener:
      return mode == "production" ? "https://function-urls.pagosdigitales.com/" : "";
    case ApiList.SosFaq:
      return mode == "production"
        ? "https://apis.pagosdigitales.com/SOS/FAQ/"
        : "https://dev.pagosdigitales.com/Apis/SOS/FAQ/";
    default:
      return "";
  }
}
