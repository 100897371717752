import Vue from "vue";
import { PaymentRequest } from "apd.jmaschihuahua.models";
import { Entrada } from "apd.gestopagos.models";
import { Solicitud } from "apd.jmasjuarez.models";
import { Solicitud as SolicitudM } from "apd.municipiojuarez.models";
import { mdiCheck, mdiClose, mdiCheckboxMarkedOutline, mdiFileDocumentOutline, mdiClipboard } from "@mdi/js";
import { GobiernoChihuahua } from "apd.models";
import {
  AxiosHttpClient,
  getUrl,
  ApiList,
  ApiGestopagos,
  ApiMunicipioJuarez,
  ApiGobiernoChihuahua,
  ApiErrorResponse,
} from "apd.apiconnectors";

export default Vue.extend({
  name: "VisorServiciosComponent",
  props: {
    idOperacion: {
      type: String,
      required: true,
    },
    servicio: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true as boolean,
      data: [] as PaymentRequest[] | Solicitud[] | SolicitudM[] | Entrada[] | GobiernoChihuahua.DatosOperacion,
      errData: null as ApiErrorResponse | null,
      icons: {
        ok: mdiCheck,
        no: mdiClose,
        check: mdiCheckboxMarkedOutline,
        ticket: mdiFileDocumentOutline,
        op: mdiClipboard,
      } as Record<string, string>,
      name: this.servicio.replace("-GP", ""),
    };
  },
  computed: {
    json(): string {
      return JSON.stringify(this.data, null, 1);
    },
    isError(): boolean {
      return this.errData == null ? false : true;
    },
    errorMsg(): string {
      if (this.errData != null && this.errData.apiError != null) {
        return this.errData.apiError.mensajeUsuario;
      } else {
        return "";
      }
    },
    errorCode(): number {
      if (this.errData != null) {
        return this.errData.statusCode;
      } else {
        return 200;
      }
    },
    alertType(): string {
      if (this.errorCode == 404) {
        return "warning";
      } else {
        return "error";
      }
    },
  },

  created() {
    this.loading = true;

    switch (this.servicio) {
      case "ATT-GP":
      case "ATTRecargas-GP":
      case "Arabela-GP":
      case "Avon-GP":
      case "Axtel-GP":
      case "Blim-GP":
      case "Blizzard-GP":
      case "BlueTelecom-GP":
      case "CFE-GP":
      case "Cinepolis-GP":
      case "CobaCafe":
      case "ComapaVictoria":
      case "CrunchyRoll-GP":
      case "Dish-GP":
      case "Fuller-GP":
      case "Herbalife-GP":
      case "IZZI-GP":
      case "Ilusion-GP":
      case "Infonavit-GP":
      case "Jafra-GP":
      case "LBEL-GP":
      case "Megacable-GP":
      case "Minecraft-GP":
      case "Movistar-GP":
      case "MovistarRecargas-GP":
      case "Natura-GP":
      case "Netflix-GP":
      case "Nextel-GP":
      case "Nintendo-GP":
      case "PlayStation-GP":
      case "Sky-GP":
      case "Spotify-GP":
      case "Starbucks-GP":
      case "StartTV-GP":
      case "Steam-GP":
      case "Telcel-GP":
      case "TelcelRecargas-GP":
      case "Telmex-GP":
      case "TotalPlay-GP":
      case "Tupperware-GP":
      case "VeTv-GP":
      case "WOW-GP":
      case "Xbox-GP":
      case "iTunes-GP":
        {
          const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
            getUrl(ApiList.Gestopagos, this.$store.getters.appMode),
          );
          axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
          const api: ApiGestopagos = new ApiGestopagos(axiosHttpClient);

          api
            .ConsultarOperacion(this.idOperacion)
            .then((data) => {
              this.data = data as Entrada[];
              this.errData = null;
            })
            .catch((err) => {
              this.errData = err as ApiErrorResponse;
            })
            .finally(() => {
              this.loading = false;
            });
        }
        break;
      case "MunicipioJuarez":
        {
          const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
            getUrl(ApiList.MunicipioJuarez, this.$store.getters.appMode),
          );
          axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
          const api: ApiMunicipioJuarez = new ApiMunicipioJuarez(axiosHttpClient);

          api
            .ConsultarSolicitudOperacion(this.idOperacion)
            .then(() => {
              //console.log(data);
              //this.data = data as SolicitudM[];
              this.errData = null;
            })
            .catch((err) => {
              this.errData = err as ApiErrorResponse;
            })
            .finally(() => {
              this.loading = false;
            });
        }
        break;
      case "GobiernoChihuahua":
        {
          const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
            getUrl(ApiList.GobiernoChihuahua, this.$store.getters.appMode),
          );
          axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
          const api: ApiGobiernoChihuahua = new ApiGobiernoChihuahua(axiosHttpClient);

          api
            .ConsultarHistorialOperacion(this.idOperacion)
            .then((data) => {
              this.data = data as GobiernoChihuahua.DatosOperacion;
              this.errData = null;
            })
            .catch((err) => {
              this.errData = err as ApiErrorResponse;
            })
            .finally(() => {
              this.loading = false;
            });
        }
        break;
      default:
        break;
    }
  },

  methods: {
    urlImage() {
      if (this.servicio.includes("Ecogas")) {
        return "https://img.pagosdigitales.com/logos/servicios/ecogas_activo_borde.svg";
      } else {
        return (
          "https://img.pagosdigitales.com/logos/servicios/" +
          this.servicio.replace("-GP", "").toLowerCase() +
          "_activo_borde.svg"
        );
      }
    },
  },
});
