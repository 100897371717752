import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "..";

@Module({
  namespaced: true,
  name: "NuevoKioskoModule",
  store,
  dynamic: true,
})
class NuevoKioskoModule extends VuexModule {
  public dialog = false;

  @Mutation
  private SET_DIALOG(data: { value: boolean }) {
    this.dialog = data.value;
  }

  @Action
  public Show() {
    this.SET_DIALOG({ value: true });
  }

  @Action
  public Hide() {
    this.SET_DIALOG({ value: false });
  }
}
export default getModule(NuevoKioskoModule);
