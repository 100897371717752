
import Vue from "vue";

export default Vue.extend({
  name: "BtnDashboard",
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      required: false,
      default: 80,
    },
    hideIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: [String, Object],
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: false,
      default: "",
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    click() {
      this.$emit("click");
      return;
    },
  },
});
