import {
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiCloseCircle,
  mdiDesktopMacDashboard,
  mdiMagnify,
} from "@mdi/js";
import commonDataModule from "@/store/modules/commonDataModule";
import snackbarModule from "@/store/modules/snackbarModule";
import spinnerModule from "@/store/modules/spinnerModule";
import { ApiSistemaPagos, AxiosHttpClient } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import { EstatusServicio, Kiosko, ModoServicio, Permiso } from "apd.sistemapagos.models";
import Vue from "vue";
import { GoToOptions } from "vuetify/types/services/goto";

export default Vue.extend({
  name: "ComisionesVueComponent",
  data() {
    return {
      Icons: {
        close: mdiClose,
        cajero: mdiDesktopMacDashboard,
        expand: mdiChevronDown,
        hide: mdiChevronUp,
        search: mdiMagnify,
        check: mdiCheckCircle,
        error: mdiCloseCircle,
      } as Record<string, string>,
      selectCajero: "",
      permisos: [] as Permiso[],
      servicios: [] as Permiso[],
      hijos: [] as Permiso[],
      listaServicios: [] as Permiso[],
      showComisiones: true,
      search: "",
    };
  },
  computed: {
    cajerosList() {
      return commonDataModule.Cajeros.sort((a, b) => (a.nombrePublico > b.nombrePublico ? 1 : -1));
    },
    filteredList(): Permiso[] {
      return this.listaServicios.filter((post) => {
        return post.nombre.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    modoServicioToString() {
      return (value: number): string => {
        switch (value) {
          case ModoServicio.ConsultaSaldo:
            return "Consulta de saldo";
          case ModoServicio.ConsultaSaldoDireccion:
            return "Consulta de saldo y dirección";
          case ModoServicio.IngresarMonto:
            return "Ingresar monto a pagar";
          case ModoServicio.ListaProductos:
            return "Lista de productos";
          default:
            return ModoServicio[value];
        }
      };
    },
  },
  async mounted() {
    await commonDataModule.LoadCajeros();
  },
  methods: {
    async permisosKiosko() {
      spinnerModule.Show();
      this.listaServicios = [];
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

      await apiSP
        .ConsultarPermisosCajero(this.selectCajero)
        .then((e) => {
          const kiosko = e as Kiosko;
          this.permisos = kiosko.permisos?.filter((permiso) => {
            return permiso.activo;
          }) as Permiso[];
          this.filtroServicios(this.permisos);
        })
        .catch(() => {
          snackbarModule.Show("No se encontraron servicios para este cajero.");
        });
      this.listaServicios = this.listaServicios
        .filter(
          (valorActual, indiceActual, arreglo) =>
            arreglo.findIndex(
              (t) => t.idServicio == valorActual.idServicio && t.idServicio === valorActual.idServicio,
            ) === indiceActual,
        )
        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
      spinnerModule.Hide();
    },
    filtroServicios(servicio: Permiso[]) {
      servicio.forEach((permiso) => {
        if (permiso.hijos) {
          this.filtroServicios(permiso.hijos);
        } else {
          if (permiso.activo && permiso.servicio?.idEstatusServicio == EstatusServicio.Activo) {
            this.listaServicios.push(permiso);
          }
        }
      });
    },
    goTo(id: string) {
      const options = { offset: 40 } as GoToOptions;
      this.$vuetify.goTo("#tag" + id, options);
    },
  },
});
