import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "../";
import { Operacion, Filtro, OperacionExtendida, OperacionRelacion } from "apd.sistemapagos.models";
import ErrorSnackbarModule from "./snackbarModule";
import spinner from "@/store/modules/spinnerModule";
import { Guid } from "guid-typescript";
import { formatISO } from "date-fns";
import { AxiosHttpClient, ApiSistemaPagos, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

@Module({
  namespaced: true,
  name: "OperacionesModule",
  store,
  dynamic: true,
})
class OperacionesModule extends VuexModule {
  public Filtro: Filtro = {
    rfc: "",
    email: "",
    referenciaPago: "",
    numeroTarjeta: "",
    folioAutorizacion: "",
    telefono: "",
    limitar: 100,
    autorizado: undefined,
    idEmpresa: Guid.EMPTY,
    idServicio: Guid.EMPTY,
    idFormaPago: Guid.EMPTY,
    municipios: [],
    estatusOperacion: [],
    kioskos: [],
    fechaInicial: undefined,
    fechaFinal: undefined,
    desencriptarInformacion: true,
  };
  public FiltroNoDispensado: Filtro = {
    rfc: "",
    email: "",
    referenciaPago: "",
    numeroTarjeta: "",
    folioAutorizacion: "",
    telefono: "",
    limitar: 0,
    autorizado: undefined,
    idEmpresa: Guid.EMPTY,
    idServicio: Guid.EMPTY,
    idFormaPago: Guid.EMPTY,
    municipios: [],
    estatusOperacion: [],
    kioskos: [],
    fechaInicial: undefined,
    fechaFinal: undefined,
    desencriptarInformacion: true,
  };
  public askFechaInicial = false;
  public askFechaFinal = false;
  public fechaInicial: string = formatISO(Date.now()).substr(0, 10);
  public fechaFinal: string = formatISO(Date.now()).substr(0, 10);
  public Operaciones: Operacion[] = [];
  public noDispensados: Operacion[] = [];
  public relacionadas: OperacionRelacion[] = [];
  public noDispensadas: OperacionExtendida[] = [];
  public validarNoDispensado = false;
  public cnd = 0;
  public get OperacionById() {
    return (id: string): Operacion => {
      return this.Operaciones.find((o) => o.id == id) as Operacion;
    };
  }

  @Mutation
  public SET_SWITCH_FECHAINICIAL(data: boolean) {
    this.askFechaInicial = data;
  }
  @Mutation
  public SET_SWITCH_FECHAFINAL(data: boolean) {
    this.askFechaFinal = data;
  }
  @Mutation
  public SET_FILTRO(data: Filtro) {
    this.Filtro = data;
  }
  @Mutation
  public SET_FILTRO_CND(data: Filtro) {
    this.FiltroNoDispensado = data;
  }
  @Mutation
  public SET_FILTRO_RFC(data: string) {
    this.Filtro.rfc = data;
  }
  @Mutation
  public SET_FILTRO_EMAIL(data: string) {
    this.Filtro.email = data;
  }
  @Mutation
  public SET_FILTRO_REFERENCIA(data: string) {
    this.Filtro.referenciaPago = data;
  }
  @Mutation
  public SET_FILTRO_TARJETA(data: string) {
    this.Filtro.numeroTarjeta = data;
  }
  @Mutation
  public SET_FILTRO_AUTH(data: string) {
    this.Filtro.folioAutorizacion = data;
  }
  @Mutation
  public SET_FILTRO_FOLIOAPD(data: string) {
    this.Filtro.folioAPD = data;
  }
  @Mutation
  public SET_FILTRO_TELEFONO(data: string) {
    this.Filtro.telefono = data;
  }
  @Mutation
  public SET_FILTRO_AUTORIZADOS(data: boolean | undefined) {
    this.Filtro.autorizado = data;
  }
  @Mutation
  public SET_FILTRO_IDEMPRESA(data: string) {
    this.Filtro.idEmpresa = data;
  }
  @Mutation
  public SET_FILTRO_IDSERVICIO(data: string) {
    this.Filtro.idServicio = data;
  }
  @Mutation
  public SET_FILTRO_IDFORMAPAGO(data: string) {
    this.Filtro.idFormaPago = data;
  }
  @Mutation
  public SET_FILTRO_MUNICIPIOS(data: number[]) {
    this.Filtro.municipios = data;
  }
  @Mutation
  public SET_FILTRO_ESTATUSOPERACION(data: number[]) {
    this.Filtro.estatusOperacion = data;
  }
  @Mutation
  public SET_FILTRO_CAJEROS(data: string[]) {
    this.Filtro.kioskos = data;
  }
  @Mutation
  public SET_FILTRO_FECHA_INICIO(data: string | undefined) {
    this.Filtro.fechaInicial = data;
  }
  @Mutation
  public SET_FILTRO_FECHA_FIN(data: string | undefined) {
    this.Filtro.fechaFinal = data;
  }
  @Mutation
  public SET_OPERACIONES(data: Operacion[]) {
    this.Operaciones = data;
  }
  @Mutation
  public SET_FECHA_INICIO(data: string) {
    this.fechaInicial = data;
  }
  @Mutation
  public SET_FECHA_FIN(data: string) {
    this.fechaFinal = data;
  }
  @Mutation
  public SET_NO_DISPENSADO(data: Operacion[]) {
    this.noDispensados = data;
  }
  @Mutation
  public SET_RELACIONADAS(data: OperacionRelacion[]) {
    this.relacionadas = data;
  }

  @Mutation
  public SET_VALIDAR_CND(data: boolean) {
    this.validarNoDispensado = data;
  }

  @Mutation
  public SET_NO_DISPENSADAS(data: OperacionExtendida[]) {
    this.noDispensadas = data;
  }
  @Mutation
  public SET_CND(value: number) {
    this.cnd = value;
  }

  @Action
  public ResetFiltro() {
    this.SET_FILTRO({
      rfc: "",
      email: "",
      referenciaPago: "",
      numeroTarjeta: "",
      folioAutorizacion: "",
      folioAPD: "",
      telefono: "",
      limitar: 100,
      autorizado: undefined,
      idEmpresa: Guid.EMPTY,
      idServicio: Guid.EMPTY,
      idFormaPago: Guid.EMPTY,
      municipios: [],
      estatusOperacion: [],
      kioskos: [],
      fechaInicial: undefined,
      fechaFinal: undefined,
      desencriptarInformacion: true,
    } as Filtro);
    this.SET_SWITCH_FECHAINICIAL(false);
    this.SET_SWITCH_FECHAFINAL(false);
    this.SET_FECHA_INICIO(formatISO(Date.now()).substr(0, 10));
    this.SET_FECHA_FIN(formatISO(Date.now()).substr(0, 10));
    this.SET_OPERACIONES([]);
  }

  @Action
  public ResetFiltroNoDispensado() {
    this.SET_FILTRO_CND({
      rfc: "",
      email: "",
      referenciaPago: "",
      numeroTarjeta: "",
      folioAutorizacion: "",
      folioAPD: "",
      telefono: "",
      limitar: 0,
      autorizado: undefined,
      idEmpresa: Guid.EMPTY,
      idServicio: Guid.EMPTY,
      idFormaPago: Guid.EMPTY,
      municipios: [],
      estatusOperacion: [],
      kioskos: [],
      desencriptarInformacion: true,
    } as Filtro);
    //this.SET_OPERACIONES([]);
  }

  @Action
  public async LoadOperaciones() {
    spinner.Show();

    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    if (this.askFechaInicial) {
      this.SET_FILTRO_FECHA_INICIO(this.fechaInicial + "T00:00:00");

      if (this.askFechaFinal) {
        this.SET_FILTRO_FECHA_FIN(this.fechaFinal + "T23:59:59");
      } else {
        this.SET_FILTRO_FECHA_FIN(this.fechaInicial + "T23:59:59");
      }
    } else {
      this.SET_FILTRO_FECHA_INICIO(undefined);
      this.SET_FILTRO_FECHA_FIN(undefined);
    }

    return apiSP
      .ConsultarOperacionesFiltro(this.Filtro)
      .then((data) => {
        this.SET_OPERACIONES(data as Operacion[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      })
      .finally(() => {
        spinner.Hide();
      });
  }

  @Action
  public async LoadOperacionesFiltro() {
    spinner.Show();

    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    if (this.askFechaInicial) {
      this.SET_FILTRO_FECHA_INICIO(this.fechaInicial + "T00:00:00");

      if (this.askFechaFinal) {
        this.SET_FILTRO_FECHA_FIN(this.fechaFinal + "T23:59:59");
      } else {
        this.SET_FILTRO_FECHA_FIN(this.fechaInicial + "T23:59:59");
      }
    } else {
      this.SET_FILTRO_FECHA_INICIO(undefined);
      this.SET_FILTRO_FECHA_FIN(undefined);
    }
    return apiSP
      .GetOperacionesFormaPago(this.Filtro)
      .then((data) => {
        this.SET_OPERACIONES(data as Operacion[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      })
      .finally(() => {
        spinner.Hide();
      });
  }

  @Action
  public async LoadOperacionesNoDispensadas() {
    spinner.Show();

    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    return apiSP
      .ConsultarOperacionesNoDispensadasFiltro(this.FiltroNoDispensado)
      .then((data) => {
        this.SET_NO_DISPENSADAS(data as OperacionExtendida[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_NO_DISPENSADAS([]);
      })
      .finally(() => {
        spinner.Hide();
      });
  }

  @Action
  public async LOAD_NO_DISPENSADO() {
    const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    clientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP = new ApiSistemaPagos(clientSP);

    return apiSP
      .ObtenerOperacionesNoDispensado()
      .then((data) => {
        this.SET_NO_DISPENSADO(data as Operacion[]);
      })
      .catch(() => {
        this.SET_NO_DISPENSADO([]);
      })
      .finally(() => {
        spinner.Hide();
      });
  }

  @Action
  public async LOAD_RELACIONADAS(id: string) {
    const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    clientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP = new ApiSistemaPagos(clientSP);

    return apiSP
      .ObtenerOperacionesRelacionadas(id)
      .then((data) => {
        this.SET_RELACIONADAS(data as OperacionRelacion[]);
      })
      .catch(() => {
        this.SET_RELACIONADAS([]);
      });
  }

  @Action
  public async VALIDAR_NO_DISPENSADO(idOperacion: string) {
    const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode));
    clientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP = new ApiSistemaPagos(clientSP);

    return await apiSP
      .ValidarNoDispensado(idOperacion)
      .then((data) => {
        this.SET_VALIDAR_CND(data > 0 ? true : false);
        this.SET_CND(data as number);
      })
      .catch(() => {
        this.SET_VALIDAR_CND(false);
        this.SET_CND(0);
      });
  }
}
export default getModule(OperacionesModule);
