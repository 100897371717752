import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "../";
//import ErrorSnackbarModule from "./snackbarModule";
import { Guid } from "guid-typescript";
import { formatISO } from "date-fns";
//import { Ticket, Estatus, PuntoAtencion } from "apd.sms.models";
//import { AxiosHttpClient, getUrl, ApiList, ApiSms, ApiErrorResponse } from "apd.apiconnectors";

@Module({
  namespaced: true,
  name: "LlamadaModule",
  store,
  dynamic: true,
})
class LlamadaModule extends VuexModule {
  public llamadaEnCurso = false;
  public timerLlamada: number | null = null;
  public duracionLlamada = 0;
  public step = 1;
  /*
  public Ticket: Ticket = {
    id: Guid.create().toString(),
    titulo: "Llamada Call Center",
    idPuntoAtencion: PuntoAtencion.callCenter,
    telefono: "",
    idUsuario: "",
    idEstatus: Estatus.abierto,
    created: formatISO(Date.now()),
    updated: formatISO(Date.now()),
  };
  */
  public IdentificadorClienteValid = false;

  // Componente principal - Control de Vista de llamada
  @Mutation
  public SET_STEP(data: number) {
    this.step = data;
  }
  @Mutation
  public SET_IDENTIFICADOR_CLIENTE_VALID(data: boolean) {
    this.IdentificadorClienteValid = data;
  }
  @Mutation
  public SET_LLAMADAENCURSO(data: boolean) {
    this.llamadaEnCurso = data;
  }
  @Mutation
  public SET_TIMER_LLAMADA(data: number | null) {
    this.timerLlamada = data;
  }
  @Mutation
  public SET_DURACION_LLAMADA(data: number) {
    this.duracionLlamada = data;
  }
  /*
  // Componente Identificador de Cliente para iniciar proceso de atencion de cliente
  @Mutation
  public SET_TICKET_NOMBRE(data: string) {
    this.Ticket.nombre = data;
  }
  @Mutation
  public SET_TICKET_APELLIDOPATERNO(data: string) {
    this.Ticket.apellidoPaterno = data;
  }
  @Mutation
  public SET_TICKET_APELLIDOMATERNO(data: string) {
    this.Ticket.apellidoMaterno = data;
  }
  @Mutation
  public SET_TICKET_TELEFONO(data: string) {
    this.Ticket.telefono = data;
  }
  @Mutation
  public SET_TICKET_EMAIL(data: string) {
    this.Ticket.email = data;
  }
  @Mutation
  public SET_TICKET_USUARIO(data: string) {
    this.Ticket.idUsuario = data;
  }
*/
  // Componente principal - Control de Vista de llamada
  @Action
  public NextStep() {
    this.SET_STEP(this.step + 1);
  }
  @Action
  public GoToStep(step: number) {
    if (step >= 2) {
      this.SET_STEP(step);
    }
  }
  @Action
  public StartCall() {
    this.SET_LLAMADAENCURSO(true);
    // this.SET_TIMER_LLAMADA(
    //   setInterval(() => {
    //     this.SET_DURACION_LLAMADA(this.duracionLlamada + 1);
    //   }, 1000),
    // );
  }
  @Action
  public PauseCall() {
    if (this.timerLlamada != null) {
      clearInterval(this.timerLlamada);
      this.SET_TIMER_LLAMADA(null);
    }
  }

  // Componente Identificador de Cliente para iniciar proceso de atencion de cliente
  @Action
  public IniciarLlamada() {
    /*const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(getUrl(ApiList.SMS, this.context.rootGetters.appMode));
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const api: ApiSms = new ApiSms(axiosHttpClient);

    return api
      .RegistrarTicket(this.Ticket)
      .then((data) => {
        if (data as boolean) {
          this.StartCall();
          this.SET_STEP(this.step + 1);
        }
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });*/
  }
}
export default getModule(LlamadaModule);
