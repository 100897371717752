import Vue from "vue";
import operacionesModule from "@/store/modules/operacionesModule";
import {
  Operacion,
  EstatusOperacion,
  ModoPago,
  TipoPago,
  TipoTarjeta,
  EstatusPago,
  Pago,
  TipoRelacion,
  OperacionRelacion,
  CorteCaja,
} from "apd.sistemapagos.models";
import {
  mdiMonitorDashboard,
  mdiPoundBox,
  mdiFileOutline,
  mdiPound,
  mdiEmail,
  mdiCellphone,
  mdiShareVariant,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiAlertCircle,
  mdiStopCircle,
  mdiCurrencyUsd,
  mdiCurrencyUsdOff,
  mdiBarcode,
  mdiCalendar,
  mdiThumbUp,
  mdiThumbDown,
  mdiHandOkay,
  mdiAlert,
  mdiPhone,
  mdiClose,
  mdiCurrencyUsdCircleOutline,
  mdiCashUsd,
  mdiCogOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiMessageText,
  mdiQrcode,
  mdiPdfBox,
  mdiFilePdf,
  mdiNumeric,
} from "@mdi/js";
import Servicios from "@/components/visores/visorServicios/visorServicios.vue";
import Componente from "@/models/componente";
import { Guid } from "guid-typescript";
import { format } from "date-fns";
import spinnerModule from "@/store/modules/spinnerModule";
import errorSnackbarModule from "@/store/modules/snackbarModule";
import { ComprobanteOperacion } from "apd.documentos.models";
import {
  AxiosHttpClient,
  ApiSistemaPagos,
  ApiDocumentos,
  ApiErrorResponse,
  ApiHeh,
  ApiSasToken,
  ApiBancos,
} from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import VueQrcode from "vue-qrcode";
import { CryptoModel } from "@/models/cryptoModel";
import snackbarModule from "@/store/modules/snackbarModule";
import { ToMoney } from "@/filters";
import { BlobServiceClient } from "@azure/storage-blob";

interface ListItem {
  id: number;
  title: string;
  subtitle: string | undefined;
  icon: string;
  color: string;
  children?: ListItem[];
}
interface AjusteOperacion {
  id: string;
  cajero?: string;
  tipoRelacion: string;
  ingreso?: string;
  egreso?: string;
  fecha: string;
  detalle?: Pago[];
  folioApd?: string;
}
interface TablaPagos {
  estatusPago: number;
  monto: number;
  numeroCuenta?: string;
}

export default Vue.extend({
  name: "OperacionView",
  components: {
    Banorte: () => import("@/components/visores/visorBanorte/visorBanorte.vue"),
    transferencia: () => import("@/components/pagarNoDispensado/pagarNoDispensado.vue"),
    Servicios,
    VueQrcode,
  },
  data() {
    return {
      comprobante: {} as ComprobanteOperacion,
      loadingDatosServicio: true as boolean,
      datosServicio: "" as string,
      loadingDatosBanco: true as boolean,
      datosBanco: "" as string,
      fab: false,
      btnQR: false,
      datosOperacion1: [] as ListItem[],
      icons: {
        share: mdiShareVariant,
        mail: mdiEmail,
        phone: mdiCellphone,
        pound: mdiPoundBox,
        success: mdiThumbUp,
        error: mdiThumbDown,
        handOk: mdiHandOkay,
        alert: mdiAlert,
        close: mdiClose,
        alertCircle: mdiAlertCircle,
        cash: mdiCurrencyUsdCircleOutline,
        total: mdiCashUsd,
        cog: mdiCogOutline,
        expand: mdiChevronDown,
        collapse: mdiChevronUp,
        qrCode: mdiQrcode,
        pdf: mdiPdfBox,
        filePdf: mdiFilePdf,
      } as Record<string, string>,
      dialog: false,
      dialogQR: false,
      encriptandoQR: false,
      fullQrCnd: "" as string,
      id: "",
      expansionPanel: [0],
      valid: true,
      datosAjustes: [] as AjusteOperacion[],
      expanded: [],
      singleExpand: false,
      isExpanded: false,
      headerPagos: [
        { text: "Importe", align: "end", sortable: false, value: "monto" },
        { text: "Estatus", align: "center", sortable: false, value: "estatusPago" },
        { text: "", align: "center", sortable: false, value: "" },
      ],
      loading: false,
      dialogPdf: false,
      selectItems: ["Comprobante de pago", "Comprobante cambio no dispensado"],
      corteCaja: {} as CorteCaja,
      ajustesHeader: [
        { text: "Fecha", align: "start", sortable: true, value: "fecha" },
        { text: "Cajero", align: "start", sortable: false, value: "cajero" },
        { text: "Ingreso", align: "end", sortable: false, value: "ingreso" },
        { text: "Egreso", align: "end", sortable: false, value: "egreso" },
        { text: "Ajuste", align: "start", sortable: false, value: "tipoRelacion" },
        { text: "", align: "end", sortable: false, value: "actions" },
      ],
      blobs: [] as string[],
      sasToken: "",
    };
  },
  computed: {
    userId() {
      return this.$store.getters["oidcStore/oidcUser"].sub;
    },
    rules() {
      return this.$store.state.validationRules;
    },
    operacion(): Operacion {
      return operacionesModule.OperacionById(this.$route.params.id);
    },
    pagosExpand(): Pago[] {
      if (this.operacion.pagos != null && this.operacion.pagos.length > 0) {
        return this.operacion.pagos;
      } else {
        return [];
      }
    },
    pagos(): TablaPagos[] | Pago[] {
      if (this.operacion.pagos != null && this.operacion.pagos.length > 0 && this.operacion.pagos != undefined) {
        if (this.operacion.pagos[0].numeroCuenta == null || this.operacion.pagos[0].numeroCuenta == undefined) {
          const array = [];
          const ingresado = this.operacion.pagos
            ?.filter((p) => p.estatusPago == EstatusPago.Ingresado)
            .reduce((p, i) => p + Number(i.monto), 0);
          const dispensado = this.operacion.pagos
            ?.filter((p) => p.estatusPago == EstatusPago.Dispensado)
            .reduce((p, i) => p + Number(i.monto), 0);
          const noDispensado = this.operacion.pagos
            ?.filter((p) => p.estatusPago == EstatusPago.NoDispensado)
            .reduce((p, i) => p + Number(i.monto), 0);

          if (ingresado > 0) {
            array.push({
              estatusPago: EstatusPago.Ingresado,
              monto: ingresado,
            });
          }
          if (dispensado > 0) {
            array.push({
              estatusPago: EstatusPago.Dispensado,
              monto: dispensado,
            });
          }
          if (noDispensado > 0) {
            array.push({
              estatusPago: EstatusPago.NoDispensado,
              monto: noDispensado,
            });
          }

          return array;
        } else {
          return this.operacion.pagos;
        }
      } else {
        return [];
      }
    },
    datosOperacion(): ListItem[] {
      return [
        {
          id: 1,
          title: "Cajero",
          subtitle: this.operacion.kiosko == undefined ? "" : this.operacion.kiosko.nombrePublico,
          icon: mdiMonitorDashboard,
          color: "",
        },
        {
          id: 2,
          title: "Servicio",
          subtitle: this.operacion.servicio == undefined ? "" : this.operacion.servicio.nombrePublico,
          icon: mdiFileOutline,
          color: "",
        },
        {
          id: 3,
          title: "Referencia",
          subtitle: this.operacion.referenciaPago,
          icon: mdiPound,
          color: "",
        },
        {
          id: 4,
          title: "Codigo de Barras",
          subtitle: this.operacion.codigoBarra == undefined ? "" : this.operacion.codigoBarra,
          icon: mdiBarcode,
          color: "",
        },
        {
          id: 5,
          title: "Folio Servicio",
          subtitle: this.operacion.folioServicio == undefined ? "" : this.operacion.folioServicio,
          icon: mdiPound,
          color: "",
        },
        {
          id: 6,
          title: "Folio Factura Comisión",
          subtitle:
            this.operacion.folioFactura == undefined || this.operacion.folioFactura === Guid.EMPTY.toString()
              ? "No Facturado"
              : this.operacion.folioFactura,
          icon: "$vuetify.icons.sat",
          color: "",
        },
        {
          id: 15,
          title: "Importe",
          subtitle:
            "$" +
            (
              this.operacion.importeServicio +
              this.operacion.importeComision +
              this.operacion.importeComisionTarjeta +
              this.operacion.importeComisionOtros +
              this.operacion.impuestoServicio +
              this.operacion.impuestoComision +
              this.operacion.impuestoComisionTarjeta +
              this.operacion.impuestoComisionOtros
            ).toFixed(2),
          icon: this.saldoOperacion > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
          color: this.saldoOperacion > 0 ? "green" : "red",
          children: [
            {
              id: 7,
              title: "Importe Servicio",
              subtitle: "$" + this.operacion.importeServicio.toFixed(2),
              icon: this.operacion.importeServicio > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.importeServicio > 0 ? "green" : "red",
            },
            {
              id: 8,
              title: "Impuesto Servicio",
              subtitle: "$" + this.operacion.impuestoServicio.toFixed(2),
              icon: this.operacion.impuestoServicio > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.impuestoServicio > 0 ? "green" : "red",
            },
            {
              id: 9,
              title: "Importe Comisión",
              subtitle: "$" + this.operacion.importeComision.toFixed(2),
              icon: this.operacion.importeComision > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.importeComision > 0 ? "green" : "red",
            },
            {
              id: 10,
              title: "IVA Comisión",
              subtitle: "$" + this.operacion.impuestoComision.toFixed(2),
              icon: this.operacion.impuestoComision > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.impuestoComision > 0 ? "green" : "red",
            },
            {
              id: 11,
              title: "Importe Comisión Tarjeta",
              subtitle: "$" + this.operacion.importeComisionTarjeta.toFixed(2),
              icon: this.operacion.importeComisionTarjeta > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.importeComisionTarjeta > 0 ? "green" : "red",
            },
            {
              id: 12,
              title: "IVA Comisión Tarjeta",
              subtitle: "$" + this.operacion.impuestoComisionTarjeta.toFixed(2),
              icon: this.operacion.impuestoComisionTarjeta > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.impuestoComisionTarjeta > 0 ? "green" : "red",
            },
            {
              id: 13,
              title: "Importe Otras Comisiones",
              subtitle: "$" + this.operacion.importeComisionOtros.toFixed(2),
              icon: this.operacion.importeComisionOtros > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.importeComisionOtros > 0 ? "green" : "red",
            },
            {
              id: 14,
              title: "IVA Otras Comisiones",
              subtitle: "$" + this.operacion.impuestoComisionOtros.toFixed(2),
              icon: this.operacion.impuestoComisionOtros > 0 ? mdiCurrencyUsd : mdiCurrencyUsdOff,
              color: this.operacion.impuestoComisionOtros > 0 ? "green" : "red",
            },
          ],
        },
        {
          id: 16,
          title: "Email",
          subtitle: this.operacion.email == undefined ? "" : this.operacion.email,
          icon: mdiEmail,
          color: "",
        },
        {
          id: 17,
          title: "Telefono",
          subtitle: this.operacion.telefono == undefined ? "" : this.operacion.telefono,
          icon: mdiPhone,
          color: "",
        },
        {
          id: 18,
          title: "Fecha",
          subtitle: format(Date.parse(this.operacion.created), "dd/MM/yyyy HH:mm:ss"),
          icon: mdiCalendar,
          color: "",
        },
        {
          id: 19,
          title: "Fecha Aplicación",
          subtitle:
            this.operacion.fechaAplicacion == null
              ? ""
              : format(Date.parse(this.operacion.fechaAplicacion), "dd/MM/yyyy HH:mm:ss"),
          icon: mdiCalendar,
          color: "",
        },
        {
          id: 20,
          title: "Observaciones",
          subtitle: this.operacion.observaciones,
          icon: mdiMessageText,
          color: "",
        },
        {
          id: 21,
          title: "Corte Caja",
          subtitle: this.corteCaja.referenciaBancaria,
          icon: mdiNumeric,
          color: "",
        },
        {
          id: 22,
          title: "Fecha corte",
          subtitle:
            this.corteCaja.created == null ? "" : format(Date.parse(this.corteCaja.created), "dd/MM/yyyy HH:mm:ss"),
          icon: mdiCalendar,
          color: "",
        },
      ];
    },
    componenteServicio(): Componente {
      if (this.operacion.servicio != undefined) {
        return {
          name: "Servicios",
          props: { idOperacion: this.operacion.id, servicio: this.operacion.servicio.nombre },
        };
      } else {
        return {
          name: "",
          props: {},
        };
      }
    },
    estatusOperacionToString() {
      return (value: number): string => {
        return EstatusOperacion[value];
      };
    },
    estatusPagoToIcon() {
      return (value: EstatusOperacion): string => {
        switch (value) {
          case EstatusOperacion.Aplicada:
            return mdiCheckCircle;
          case EstatusOperacion.AplicadaIncompleta:
            return mdiAlertCircle;
          case EstatusOperacion.Bloqueada:
            return mdiStopCircle;
          case EstatusOperacion.Cancelada:
            return mdiCloseCircle;
          case EstatusOperacion.Cobrada:
            return mdiAlertCircle;
          case EstatusOperacion.Fraude:
            return mdiAlertCircle;
          case EstatusOperacion.Incompleta:
            return mdiAlertCircle;
          case EstatusOperacion.Nueva:
            return mdiAlertCircle;
          case EstatusOperacion.Rechazada:
            return mdiAlertCircle;
          case EstatusOperacion.Reembolsada:
            return mdiAlertCircle;
          case EstatusOperacion.Revision:
            return mdiAlertCircle;
          case EstatusOperacion.Verificada:
            return mdiAlertCircle;
          case EstatusOperacion.NoDispensadoPagado:
            return mdiCheckCircle;
          case EstatusOperacion.NoDispensadoPorPagar:
            return mdiAlertCircle;
          default:
            return "";
        }
      };
    },
    modoPagoToString() {
      return (value: number): string => {
        return ModoPago[value];
      };
    },
    estatusPagoToString() {
      return (value: number): string => {
        return EstatusPago[value];
      };
    },
    numeroCuenta() {
      return (value: string): string => {
        return "**** **** **** " + value.substring(value.length - 4);
      };
    },
    tipoPagoToString() {
      return (value: number): string => {
        return TipoPago[value];
      };
    },
    saldoPagos(): number {
      if (this.operacion.pagos != null && this.operacion.pagos.length > 0) {
        let saldo = 0;
        this.operacion.pagos.forEach((item) => {
          if (item.modoPago == ModoPago.Ingreso) {
            saldo += item.monto;
          }
          if (item.modoPago == ModoPago.Egreso) {
            saldo -= item.monto;
          }
        });
        return saldo;
      } else {
        return 0;
      }
    },
    saldoOperacion(): number {
      if (this.operacion.pagos != null && this.operacion.pagos.length > 0) {
        if (this.operacion.estatusOperacion == EstatusOperacion.Cancelada) {
          return 0;
        } else {
          return (
            this.operacion.importeServicio +
            this.operacion.importeComision +
            this.operacion.importeComisionTarjeta +
            this.operacion.importeComisionOtros +
            this.operacion.impuestoServicio +
            this.operacion.impuestoComision +
            this.operacion.impuestoComisionTarjeta +
            this.operacion.impuestoComisionOtros
          );
        }
      } else {
        return 0;
      }
    },
    diferencia(): number {
      if (this.operacion.pagos != undefined && this.operacion.pagos.length > 0) {
        if (
          this.operacion.estatusOperacion == EstatusOperacion.NoDispensadoPagado ||
          this.operacion.estatusOperacion == EstatusOperacion.NoDispensadoPorPagar
        ) {
          return 0;
        } else {
          if (
            this.operacion.estatusOperacion != EstatusOperacion.Aplicada &&
            this.operacion.estatusOperacion != EstatusOperacion.Cobrada
          ) {
            return this.saldoPagos;
          } else {
            return this.saldoPagos - this.saldoOperacion;
          }
        }
      } else {
        return 0;
      }
    },
    pagosRelacionados(): OperacionRelacion[] {
      return operacionesModule.relacionadas;
    },
    noDispensado(): number {
      let pagosR = 0;
      if (operacionesModule.relacionadas.length > 0 && operacionesModule.relacionadas != null) {
        operacionesModule.relacionadas.forEach((relacionada) => {
          if (relacionada.tipoRelacion == 0 && relacionada.operacionRelacionada.pagos != undefined) {
            pagosR = relacionada.operacionRelacionada?.pagos
              ?.filter((p) => p.estatusPago == EstatusPago.Dispensado)
              .reduce((p, i) => p + Number(i.monto), 0);
          }
        });
      }
      const pago = this.operacion.pagos as Pago[];
      return (
        pago.filter((p) => p.estatusPago == EstatusPago.NoDispensado).reduce((p, i) => p + Number(i.monto), 0) - pagosR
      );
    },
    saldoNoDispensado(): boolean {
      if (
        this.operacion.estatusOperacion == EstatusOperacion.NoDispensadoPagado ||
        this.operacion.estatusOperacion == EstatusOperacion.NoDispensadoPorPagar
      ) {
        return false;
      } else {
        return operacionesModule.validarNoDispensado;
      }
    },
    QrCnd(): string {
      if (this.saldoNoDispensado) {
        let op: string;
        if (this.pagosRelacionados.length > 0) {
          op = this.pagosRelacionados[this.pagosRelacionados.length - 1].idOperacionRelacionada;
        } else {
          op = "";
        }

        return "IDOperacion=" + this.operacion.id + "|" + "IDOperacionRelacionada=" + op;
      }

      return "";
    },
    cnd(): number {
      return operacionesModule.cnd;
    },
  },
  watch: {
    dialogQR: {
      handler: async function (newVal) {
        if (newVal) {
          const data = await this.GenerarCadenaQr(this.QrCnd);
          this.fullQrCnd = data == "" ? "" : "CambioNoDispensado:" + data;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.loading = true;
    await operacionesModule.LOAD_RELACIONADAS(this.operacion.id);
    await operacionesModule.VALIDAR_NO_DISPENSADO(this.operacion.id);
    if (this.operacion.idCorteCaja != "00000000-0000-0000-0000-000000000000") {
      this.corte();
    }
    this.loading = false;
  },
  methods: {
    ajustes() {
      this.datosAjustes = [];
      operacionesModule.relacionadas.forEach((relacionada) => {
        if (relacionada.operacionRelacionada.pagos) {
          this.datosAjustes.push({
            id: relacionada.idOperacionRelacionada,
            tipoRelacion: TipoRelacion[relacionada.tipoRelacion],
            ingreso: ToMoney(
              relacionada.operacionRelacionada.pagos
                ?.filter((p) => p.modoPago == ModoPago.Ingreso)
                .reduce((p, i) => p + Number(i.monto), 0),
            ),
            egreso: ToMoney(
              relacionada.operacionRelacionada.pagos
                ?.filter((p) => p.modoPago == ModoPago.Egreso)
                .reduce((p, i) => p + Number(i.monto), 0),
            ),
            fecha: format(Date.parse(relacionada.operacionRelacionada.created), "dd/MM/yyyy HH:mm:ss"),
            cajero: relacionada.operacionRelacionada.kiosko?.nombrePublico,
            detalle: relacionada.operacionRelacionada.pagos,
            folioApd: relacionada.operacionRelacionada.folioAPD,
          });
        }
      });
      this.datosAjustes;
    },
    async descargarComp(folioApd: string) {
      const clientST = new AxiosHttpClient(getUrl(ApiList.SasToken, this.$store.getters.appMode));
      clientST.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiST = new ApiSasToken(clientST);
      this.sasToken = apiST.GetSasToken();

      const blobSasUrl = "https://transferencias.sos.kioskos.net/" + this.sasToken;
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      const containerClient = blobServiceClient.getContainerClient("transferencias");

      this.blobs = [] as string[];
      const blob = (this.operacion.folioAPD + "_" + folioApd + ".pdf").replace(/\s/g, "");
      try {
        const blobName = blob;
        const blobClient = containerClient.getBlockBlobClient(blobName);
        const downloadBlockBlobResponse = await blobClient.download();
        if (downloadBlockBlobResponse.blobBody != undefined) {
          const fileURL = URL.createObjectURL(await downloadBlockBlobResponse.blobBody);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = blobName;
          link.click();
        } else {
          errorSnackbarModule.Show("No se encontro el comprobante.");
          this.dialog = false;
        }
      } catch (error) {
        errorSnackbarModule.Show("No se encontro el comprobante.");
        this.dialog = false;
      }
    },
    TipoTarjetaToString(idx: number) {
      return TipoTarjeta[idx];
    },
    async GenerarComprobante(mail: string | undefined, funcion: string) {
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

      //Generar comprobante
      await apiSP
        .ConsultarComprobanteOperacion(this.operacion.id)
        .then((e) => {
          spinnerModule.Show;
          this.comprobante = e as ComprobanteOperacion;
          if (funcion == "mail" && mail != undefined) {
            this.EnviarComprobante(mail);
          } else {
            this.DescargarPdf();
          }
        })
        .catch((error) => {
          const x = error as ApiErrorResponse;
          x.apiError.mensajeUsuario;
          errorSnackbarModule.Show(x.apiError.mensajeUsuario + " No es posible generar el comprobante.");
          this.dialog = false;
        })
        .finally(() => {
          spinnerModule.Hide;
        });
    },
    EnviarComprobante(mail: string) {
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.Documentos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiD: ApiDocumentos = new ApiDocumentos(axiosHttpClient);

      apiD
        .MailComprobantePago(mail, this.comprobante)
        .then((e) => {
          spinnerModule.Show;

          if (e == true) {
            errorSnackbarModule.ShowSuccess("Email enviado correctamente");
          } else {
            errorSnackbarModule.Show("No es posible enviar el email.");
          }
        })
        .catch((error) => {
          const x = error as ApiErrorResponse;
          x.apiError.mensajeUsuario;
          errorSnackbarModule.Show(x.apiError.mensajeUsuario + ". No es posible enviar el email.");
          this.dialog = false;
        })
        .finally(() => {
          spinnerModule.Hide;
          this.dialog = false;
        });
    },
    async DescargarPdf() {
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.Documentos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiD: ApiDocumentos = new ApiDocumentos(axiosHttpClient);

      await apiD
        .GenerarComprobantePago(this.comprobante)
        .then((e) => {
          const base64 = e as string;
          const fileName = "Comprobante " + this.operacion.folioAPD + ".pdf";

          const pdfWindow = window.open("about:blank", "_blank");
          if (pdfWindow) {
            pdfWindow.document.write(
              "<html<head><title>" +
                fileName +
                "</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>",
            );
            pdfWindow.document.write(
              "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(base64) +
                "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>",
            );
          }
        })
        .catch(() => {
          snackbarModule.Show("No es posible mostrar el comprobante de pago.");
        });
    },
    async CargarDatosBanco() {
      // TODO Mover esto a un componente

      if (this.datosBanco == "") {
        const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
          getUrl(ApiList.Bancos, this.$store.getters.appMode),
        );
        axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
        const apiB: ApiBancos = new ApiBancos(axiosHttpClient);

        try {
          this.loadingDatosBanco = true;
          if (this.operacion.folioAPD != undefined) {
            const data = await apiB.GetRequest3DsOperacion(this.operacion.id);
            this.datosBanco = JSON.stringify(data);
          }
        } catch (ex) {
          //console.log(ex);
        } finally {
          setTimeout(() => {
            this.loadingDatosBanco = false;
          }, 2000);
        }
      }
    },
    async GenerarCadenaQr(value: string) {
      const cryptoData: CryptoModel[] = [];
      let crypted: CryptoModel[] = [];
      cryptoData.push({ value: value, group: 0 });
      const client = new AxiosHttpClient(getUrl(ApiList.HEH, this.$store.getters.appMode));
      client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiCrypt = new ApiHeh(client);

      try {
        this.encriptandoQR = true;
        crypted = (await apiCrypt.Encode<CryptoModel[]>(cryptoData)) as CryptoModel[];
        return crypted[0].value;
      } catch (err) {
        return "";
      } finally {
        this.encriptandoQR = false;
      }
    },
    async corte() {
      const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
        getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
      );
      axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);
      await apiSP.ConsultarCorteCaja(this.operacion.idCorteCaja).then((e) => {
        this.corteCaja = e as CorteCaja;
      });
    },
  },
});
