import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "../";
import {
  Kiosko,
  Servicio,
  Empresa,
  FormaPago,
  DispositivoMoneda,
  DispositivoBillete,
  Banco,
  Sucursal,
} from "apd.sistemapagos.models";
import ErrorSnackbarModule from "./snackbarModule";
import { Guid } from "guid-typescript";
import { AxiosHttpClient, ApiSistemaPagos, ApiErrorResponse, ApiBancos } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import { Banorte } from "apd.models/";

@Module({
  namespaced: true,
  name: "commonDataModule",
  store,
  dynamic: true,
})
class CommonDataModule extends VuexModule {
  public Cajeros: Kiosko[] = [];
  public DispositivoMoneda: DispositivoMoneda[] = [];
  public DispositivoBillete: DispositivoBillete[] = [];
  public Banco: Banco[] = [];
  public Sucursal: Sucursal[] = [];
  public Afiliacion: Banorte.Payworks2.Afiliacion[] = [];
  public Servicios: Servicio[] = [
    {
      id: Guid.EMPTY.toString(),
      nombrePublico: "Todos los Servicios",
      nombre: "",
      nombreReportes: "",
      idTipoServicio: "",
      idEstatusServicio: 0,
      idEmpresa: Guid.EMPTY.toString(),
      servicioTop: false,
      estado: "",
      municipio: "",
      regExpCodigoBarra: "",
      regExpReferencia: "",
      etiquetaReferencia: "",
      created: "",
      updated: "",
      pagosParciales: false,
      modoServicio: 0,
    },
  ];
  public Empresas: Empresa[] = [
    {
      id: Guid.EMPTY.toString(),
      nombre: "Todas las Empresas",
      idGiroEmpresa: "",
      idMunicipio: 0,
      abreviacion: "",
      rfc: "",
      urlWebsite: "",
      created: "",
      updated: "",
    },
  ];
  public FormasPago: FormaPago[] = [
    {
      id: Guid.EMPTY.toString(),
      nombre: "Todas las Formas de Pago",
      tipoCuentaDestino: "",
      sat: "",
      created: "",
      updated: "",
    },
  ];
  public get BancoById() {
    return (id: string): Banco => {
      return this.Banco.find((o) => o.id == id) as Banco;
    };
  }

  @Mutation
  public SET_CAJEROS(value: Kiosko[]) {
    this.Cajeros = value;
  }
  @Mutation
  public SET_SUCURSAL(value: Sucursal[]) {
    this.Sucursal = value;
  }
  @Mutation
  public SET_AFILIACION(value: Banorte.Payworks2.Afiliacion[]) {
    this.Afiliacion = value;
  }
  @Mutation
  public SET_BANCO(value: Banco[]) {
    this.Banco = value;
  }
  @Mutation
  public SET_SERVICIOS(value: Servicio[]) {
    this.Servicios = this.Servicios.concat(value);
  }
  @Mutation
  public SET_EMPRESAS(value: Empresa[]) {
    this.Empresas = this.Empresas.concat(value);
  }
  @Mutation
  public SET_FORMASPAGO(value: FormaPago[]) {
    this.FormasPago = this.FormasPago.concat(value);
  }
  @Mutation
  public SET_MONEDA(value: DispositivoMoneda[]) {
    this.DispositivoMoneda = value;
  }
  @Mutation
  public SET_DISPOSITIVOBILLETE(value: DispositivoBillete[]) {
    this.DispositivoBillete = value;
  }

  @Action
  public async LoadEmpresas() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetEmpresas()
      .then((data) => {
        this.SET_EMPRESAS(data as Empresa[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadCajeros() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetCajeros()
      .then((data) => {
        this.SET_CAJEROS(data as Kiosko[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadServicios() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetServicios()
      .then((data) => {
        this.SET_SERVICIOS(data as Servicio[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadFormasPago() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetFormasPago()
      .then((data) => {
        this.SET_FORMASPAGO(data as FormaPago[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadAfiliacion(idEmpresa: string) {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.Bancos, this.context.rootGetters.appMode),
    );

    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiB: ApiBancos = new ApiBancos(axiosHttpClient);

    return apiB
      .GetAfiliacionEmpresa(idEmpresa)
      .then((data) => {
        this.SET_AFILIACION(data as Banorte.Payworks2.Afiliacion[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }

  @Action
  public async LoadSucursal() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetSucursales()
      .then((data) => {
        this.SET_SUCURSAL(data as Sucursal[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadBancos() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetBancos()
      .then((data) => {
        this.SET_BANCO(data as Banco[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadDispositivoMoneda() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetDispositivosMoneda()
      .then((data) => {
        this.SET_MONEDA(data as DispositivoMoneda[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadDispositivoBillete() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    return apiSP
      .GetDispositivosBillete()
      .then((data) => {
        this.SET_DISPOSITIVOBILLETE(data as DispositivoBillete[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
}
export default getModule(CommonDataModule);
