import store from "../";
import { getModule, Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { AxiosHttpClient, ApiSosFaq, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import { Articulo, Categoria } from "apd.sos.faq.models";
import spinnerModule from "./spinnerModule";

@Module({
  namespaced: true,
  name: "preguntasModule",
  store,
  dynamic: true,
})
class PreguntasModule extends VuexModule {
  public categorias: Categoria[] = [];
  public articulos: Articulo[] = [];
  public lastCall: Articulo[] = [];

  public get ArticuloById() {
    return (id: number): Articulo => {
      return this.articulos.find((o) => o.id == id) as Articulo;
    };
  }

  @Mutation
  public SET_CATEGORIAS(data: Categoria[]) {
    this.categorias = data;
  }

  @Mutation
  public SET_ARTICULOS(data: Articulo[]) {
    this.articulos = data;
  }

  @Mutation
  public SET_LAST_CALL(data: Articulo[]) {
    this.lastCall = data;
  }

  @Action
  public async LOAD_CATEGORIAS() {
    const clientFaq = new AxiosHttpClient(getUrl(ApiList.SosFaq, this.context.rootGetters.appMode));
    clientFaq.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiFaq = new ApiSosFaq(clientFaq);

    return await apiFaq
      .ConsultarArticulosCategorias()
      .then((e) => {
        spinnerModule.Show();
        this.SET_CATEGORIAS(e as Categoria[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        this.SET_CATEGORIAS([]);
      })
      .finally(() => {
        spinnerModule.Hide();
      });
  }

  @Action
  public async LOAD_ARTICULOS_CATEGORIA(idCategoria: number) {
    const clientFaq = new AxiosHttpClient(getUrl(ApiList.SosFaq, this.context.rootGetters.appMode));
    clientFaq.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiFaq = new ApiSosFaq(clientFaq);

    return await apiFaq
      .ConsultarArticuloCategoria(idCategoria)
      .then((e) => {
        spinnerModule.Show();
        this.SET_LAST_CALL(e as Articulo[]);
        this.SET_ARTICULOS(e as Articulo[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
      })
      .finally(() => {
        spinnerModule.Hide();
      });
  }

  @Action
  public async LOAD_ARTICULOS_KEY(key: string) {
    if (key == undefined) {
      key = "";
    }
    const clientFaq = new AxiosHttpClient(getUrl(ApiList.SosFaq, this.context.rootGetters.appMode));
    clientFaq.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiFaq = new ApiSosFaq(clientFaq);

    return await apiFaq
      .ArticulosPalabraClave(key)
      .then((e) => {
        spinnerModule.Show();
        this.SET_LAST_CALL(e as Articulo[]);
      })
      .catch((error) => {
        const x = error as ApiErrorResponse;
        x.apiError.mensajeUsuario;
      })
      .finally(() => {
        spinnerModule.Hide();
      });
  }
}
export default getModule(PreguntasModule);
