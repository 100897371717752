import Vue from "vue";
import { mdiLogout } from "@mdi/js";

export default Vue.extend({
  name: "AppComponent",
  components: {
    AppFooter: () => import("@/components/appFooter.vue"),
    AppMenu: () => import("@/components/appMenu/appMenu.vue"),
    AppToolbar: () => import("@/components/appToolbar/appToolbar.vue"),
    AppSnackbar: () => import("@/components/appSnackbar/appSnackbar.vue"),
    Spinner: () => import("@/components/spinner.vue"),
  },
  data() {
    return {
      IconLogout: mdiLogout as string,
      LeftDrawer: false as boolean,
      RightDrawer: false as boolean,
    };
  },
  computed: {
    IsAuthenticated(): boolean | undefined {
      return this.$store.getters["oidcStore/oidcIsAuthenticated"];
    },
    IsDevMode(): boolean {
      if (process.env.NODE_ENV === "production") {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    Logout(): void {
      this.$store.dispatch("oidcStore/signOutOidc");
    },
  },
});
