import Vue from "vue";
import operacionesModule from "@/store/modules/operacionesModule";
import CommonDataModule from "@/store/modules/commonDataModule";
import { Kiosko, Servicio, Empresa, FormaPago, Operacion, EstatusOperacion } from "apd.sistemapagos.models";
import { Guid } from "guid-typescript";
import {
  mdiCloseBox,
  mdiMinusBox,
  mdiCheckboxBlankOutline,
  mdiCalendar,
  mdiClose,
  mdiContentSaveMove,
  mdiMonitorScreenshot,
} from "@mdi/js";

export default Vue.extend({
  name: "FiltroOperacionComponent",
  data() {
    return {
      menuFechaInicial: false as boolean,
      menuFechaFinal: false as boolean,
      Icon: {
        Calendar: mdiCalendar,
        Close: mdiClose,
        Content: mdiContentSaveMove,
        Monitor: mdiMonitorScreenshot,
      } as Record<string, string>,
      masFiltros: false as boolean,
      dialog: false as boolean,
      headers: [
        {
          text: "Fecha",
          value: "created",
          align: "start",
          sortable: false,
        },
        {
          text: "Folio APD",
          value: "folioAPD",
          align: "start",
          sortable: false,
        },
        {
          text: "Cajero",
          value: "kiosko.nombrePublico",
          align: "start",
          sortable: false,
        },
        {
          text: "Servicio",
          value: "servicio.nombrePublico",
          align: "start",
          sortable: false,
        },
        {
          text: "Referencia",
          value: "referenciaPago",
          align: "start",
          sortable: false,
        },
        {
          text: "Estatus",
          value: "estatusOperacion",
          align: "start",
          sortable: false,
        },
        {
          text: "Folio Servicio",
          value: "folioServicio",
          align: "start",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          align: "start",
          sortable: false,
        },
        {
          text: "Telefono",
          value: "telefono",
          align: "start",
          sortable: false,
        },
        {
          text: "Importe Servicio",
          value: "importeServicio",
          align: "end",
          sortable: false,
        },
        {
          text: "Comisión",
          value: "",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    estatusOperacionToString() {
      return (value: number): string => {
        return EstatusOperacion[value];
      };
    },
    inputColor(): string {
      if (this.$vuetify.theme.dark) {
        return "green lighten-1";
      } else {
        return "green darken-4";
      }
    },
    rules() {
      return this.$store.state.validationRules;
    },
    cajerosList(): Kiosko[] {
      return CommonDataModule.Cajeros;
    },
    serviciosList(): Servicio[] {
      if (this.idEmpresa == Guid.EMPTY.toString()) {
        return CommonDataModule.Servicios;
      } else {
        return CommonDataModule.Servicios.filter((s) => s.idEmpresa == this.idEmpresa.toString());
      }
    },
    empresasList(): Empresa[] {
      return CommonDataModule.Empresas;
    },
    formasPagoList(): FormaPago[] {
      return CommonDataModule.FormasPago;
    },
    askFechaInicial: {
      get(): boolean {
        return operacionesModule.askFechaInicial;
      },
      set(value: boolean): void {
        operacionesModule.SET_SWITCH_FECHAINICIAL(value);
      },
    },
    askFechaFinal: {
      get(): boolean {
        return operacionesModule.askFechaFinal;
      },
      set(value: boolean): void {
        operacionesModule.SET_SWITCH_FECHAFINAL(value);
      },
    },
    rfc: {
      get(): string {
        if (operacionesModule.Filtro.rfc != undefined) {
          return operacionesModule.Filtro.rfc;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_RFC(value.toUpperCase());
      },
    },
    email: {
      get(): string {
        if (operacionesModule.Filtro.email != undefined) {
          return operacionesModule.Filtro.email;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_EMAIL(value);
      },
    },
    referencia: {
      get(): string {
        if (operacionesModule.Filtro.referenciaPago != undefined) {
          return operacionesModule.Filtro.referenciaPago;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_REFERENCIA(value);
      },
    },
    tarjeta: {
      get(): string {
        if (operacionesModule.Filtro.numeroTarjeta != undefined) {
          return operacionesModule.Filtro.numeroTarjeta;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_TARJETA(value);
      },
    },
    FolioAutorizacion: {
      get(): string {
        if (operacionesModule.Filtro.folioAutorizacion != undefined) {
          return operacionesModule.Filtro.folioAutorizacion;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_AUTH(value.toUpperCase());
      },
    },
    folioAPD: {
      get(): string {
        if (operacionesModule.Filtro.folioAPD != undefined) {
          return operacionesModule.Filtro.folioAPD;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_FOLIOAPD(value);
      },
    },
    telefono: {
      get(): string {
        if (operacionesModule.Filtro.telefono != undefined) {
          return operacionesModule.Filtro.telefono;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_TELEFONO(value);
      },
    },
    autorizados: {
      get(): boolean | null {
        if (operacionesModule.Filtro.autorizado != undefined) {
          return operacionesModule.Filtro.autorizado;
        } else {
          return null;
        }
      },
      set(value: boolean | undefined): void {
        operacionesModule.SET_FILTRO_AUTORIZADOS(value);
      },
    },
    idEmpresa: {
      get(): string {
        if (operacionesModule.Filtro.idEmpresa != undefined) {
          return operacionesModule.Filtro.idEmpresa;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_IDEMPRESA(value);
      },
    },
    idServicio: {
      get(): string {
        if (operacionesModule.Filtro.idServicio != undefined) {
          return operacionesModule.Filtro.idServicio;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_IDSERVICIO(value);
      },
    },
    idFormaPago: {
      get(): string {
        if (operacionesModule.Filtro.idFormaPago != undefined) {
          return operacionesModule.Filtro.idFormaPago;
        } else {
          return "";
        }
      },
      set(value: string): void {
        operacionesModule.SET_FILTRO_IDFORMAPAGO(value);
      },
    },
    municipios: {
      get(): number[] {
        if (operacionesModule.Filtro.municipios != undefined) {
          return operacionesModule.Filtro.municipios;
        } else {
          return [];
        }
      },
      set(value: number[]): void {
        operacionesModule.SET_FILTRO_MUNICIPIOS(value);
      },
    },
    estatusOperacion: {
      get(): number[] {
        if (operacionesModule.Filtro.estatusOperacion != undefined) {
          return operacionesModule.Filtro.estatusOperacion;
        } else {
          return [];
        }
      },
      set(value: number[]): void {
        operacionesModule.SET_FILTRO_ESTATUSOPERACION(value);
      },
    },
    cajeros: {
      get(): string[] {
        if (operacionesModule.Filtro.kioskos != undefined) {
          return operacionesModule.Filtro.kioskos;
        } else {
          return [];
        }
      },
      set(value: string[]): void {
        operacionesModule.SET_FILTRO_CAJEROS(value);
      },
    },
    fechaInicial: {
      get(): string {
        return operacionesModule.fechaInicial;
      },
      set(value: string): void {
        operacionesModule.SET_FECHA_INICIO(value);
      },
    },
    fechaFinal: {
      get(): string {
        return operacionesModule.fechaFinal;
      },
      set(value: string): void {
        operacionesModule.SET_FECHA_FIN(value);
      },
    },
    TodosCajeros(): boolean {
      return this.cajeros.length === this.cajerosList.length;
    },
    AlgunosCajeros(): boolean {
      return this.cajeros.length > 0 && !this.TodosCajeros;
    },
    icon(): string {
      if (this.TodosCajeros) return mdiCloseBox;
      if (this.AlgunosCajeros) return mdiMinusBox;
      return mdiCheckboxBlankOutline;
    },
    operaciones(): Operacion[] {
      return operacionesModule.Operaciones;
    },
    operacionesCount(): number {
      return operacionesModule.Operaciones.length;
    },
  },
  async created() {
    const tasks: Promise<void>[] = [];

    if (this.cajerosList.length <= 0) {
      tasks.push(CommonDataModule.LoadCajeros());
    }
    if (this.empresasList.length <= 1) {
      tasks.push(CommonDataModule.LoadEmpresas());
    }
    if (this.serviciosList.length <= 1) {
      tasks.push(CommonDataModule.LoadServicios());
    }
    if (this.formasPagoList.length <= 1) {
      tasks.push(CommonDataModule.LoadFormasPago());
    }
    if (tasks.length > 0) {
      await Promise.all(tasks);
    }
  },
  methods: {
    btnResetFiltroClick() {
      operacionesModule.ResetFiltro();
    },
    async btnBuscarClick() {
      await operacionesModule.LoadOperaciones().finally(() => {
        if (this.operacionesCount > 0) {
          this.dialog = true;
        }
      });
    },
    selectCajerosChange() {
      if (this.cajeros.find((c: string) => c == Guid.EMPTY.toString()) === Guid.EMPTY.toString()) {
        this.cajeros = [];
        this.cajerosList.forEach((element) => {
          this.cajeros.push(element.id);
        });
      }
    },
    toggleCajeros(): void {
      this.$nextTick(() => {
        if (this.TodosCajeros) {
          this.cajeros = [];
        } else {
          this.cajeros = [];
          this.cajerosList.forEach((element) => {
            this.cajeros.push(element.id);
          });
        }
      });
    },
    swithFechaChange(): void {
      if (!this.askFechaInicial) {
        this.askFechaFinal = false;
      }
    },
    swithRangoFechaChange(): void {
      if (this.askFechaFinal) {
        operacionesModule.SET_FECHA_FIN(this.fechaInicial);
      }
    },
    gotoRoute(url: string) {
      this.$router.push(url);
    },
  },
});
