import Vue from "vue";
import Vuex from "vuex";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import DevoidcSettings from "../plugins/oidcSettings.dev";
import ProdoidcSettings from "../plugins/oidcSettings.prod";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: process.env.VUE_APP_VERSION || "0",
    mode: process.env.NODE_ENV as string,
    validationRules: {
      email: (v: string) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Email no tiene formato.",
      length: (v: string, len: number) => {
        return (v || "").length >= len || `Debe ingresar minimo ${len} caracteres.`;
      },
      password: (v: string) => {
        return (
          (v || "").match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
          "Password debe contener una mayuscula, numero y caracter especial."
        );
      },
      maxValue: (v: number, max: number) => {
        return v <= max || "La cantidad ingresada rebasa el monto admitido";
      },
      minValue: (v: number, min: number) => {
        return v >= min || `El monto minimo es ${min}.`;
      },
      clabe: (v: string | undefined, prefijo: string) => {
        if (v?.length != 18) {
          return "Longitud invalida";
        }
        return v?.substring(0, 3) == prefijo || "Prefijo de la clabe invalido";
      },
      required: (v: string) => !!v || "Dato obligatorio",
      phone: (v: string) =>
        !v || /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})?([ .-]?)([0-9]{4})/.test(v) || "Telefono invalido.",
    },
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    appMode: (state) => {
      return state.mode;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      process.env.NODE_ENV === "production" ? ProdoidcSettings : DevoidcSettings,
      {
        namespaced: true,
        routeBase: process.env.BASE_URL,
      },
      {
        // userLoaded: (user: any) => console.log('OIDC user is loaded:', user),
        // userUnloaded: () => console.log('OIDC user is unloaded'),
        // accessTokenExpiring: () => console.log('Access token will expire'),
        // accessTokenExpired: () => console.log('Access token did expire'),
        // silentRenewError: () => console.log('OIDC user is unloaded'),
        // userSignedOut: () => console.log('OIDC user is signed out'),
      },
    ),
  },
});
