import { render, staticRenderFns } from "./visorServicios.vue?vue&type=template&id=f31264b8"
import script from "./visorServicios.ts?vue&type=script&lang=ts&external"
export * from "./visorServicios.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports