import Vue from "vue";
import llamadaModule from "@/store/modules/llamadaModule";

export default Vue.extend({
  name: "IdentificadorClienteComponent",
  computed: {
    rules() {
      return this.$store.state.validationRules;
    },
    validForm: {
      get(): boolean {
        return llamadaModule.IdentificadorClienteValid;
      },
      set(value: boolean) {
        llamadaModule.SET_IDENTIFICADOR_CLIENTE_VALID(value);
      },
    },

    nombre: {
      get(): string {
        return "";
        // if (llamadaModule.Ticket.nombre == undefined) {
        //   return "";
        // } else {
        //   return llamadaModule.Ticket.nombre;
        // }
      },
      set(value: string) {
        return "";
        //llamadaModule.SET_TICKET_NOMBRE(value);
      },
    },
    apellidoPaterno: {
      get(): string {
        return "";
        /*
        if (llamadaModule.Ticket.apellidoPaterno == undefined) {
          return "";
        } else {
          return llamadaModule.Ticket.apellidoPaterno;
        }
          */
      },
      set(value: string) {
        return "";
        //llamadaModule.SET_TICKET_APELLIDOPATERNO(value);
      },
    },
    apellidoMaterno: {
      get(): string {
        /*
        if (llamadaModule.Ticket.apellidoMaterno == undefined) {
          return "";
        } else {
          return llamadaModule.Ticket.apellidoMaterno;
        }
        */
        return "";
      },
      set(value: string) {
        //llamadaModule.SET_TICKET_APELLIDOMATERNO(value);
        return "";
      },
    },
    telefono: {
      get(): string {
        //return llamadaModule.Ticket.telefono;
        return "";
      },
      set(value: string) {
        //llamadaModule.SET_TICKET_TELEFONO(value);
        return "";
      },
    },
    email: {
      get(): string {
        /*
        if (llamadaModule.Ticket.email == undefined) {
          return "";
        } else {
          return llamadaModule.Ticket.email;
        }
        */
        return "";
      },
      set(value: string) {
        return "";
        //llamadaModule.SET_TICKET_EMAIL(value);
      },
    },
  },
  mounted() {
    // llamadaModule.SET_TICKET_USUARIO(this.$store.getters["oidcStore/oidcUser"].sub);
  },
  methods: {
    btnResetClick() {
      (this.$refs.frmDatosCliente as HTMLFormElement).reset();
    },
    btnSiguienteClick() {
      // TODO Activar proceso de iniciado de llamada
      //llamadaModule.IniciarLlamada();
      llamadaModule.StartCall();
      llamadaModule.NextStep();
    },
  },
});
