import Home from "@/views/Home.vue";
import llamada from "@/views/llamada/llamada.vue";
import operaciones from "@/views/operaciones/operaciones.vue";
import toneleros from "@/views/toneleros/toneleros.vue";
import operacion from "@/views/operacion/operacion.vue";
import cajeros from "@/views/cajeros/cajeros.vue";
import preguntas from "@/views/preguntas/preguntas.vue";
import comisiones from "@/views/comisiones/comisiones.vue";
import Callback from "@/views/callback/callback.vue";
import CallbackError from "@/views/callbackError.vue";
import Denegado from "@/views/denegado.vue";
import NotFound from "@/views/notFound.vue";

// {
// path: '/about',
// name: 'about',
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
// },

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/llamada", name: "Llamada", component: llamada },
  { path: "/operaciones", name: "Operaciones", component: operaciones },
  { path: "/operacion/:id", name: "Operacion", component: operacion },
  { path: "/toneleros", name: "Toneleros", component: toneleros },
  { path: "/cajeros", name: "Cajeros", component: cajeros },
  { path: "/preguntas", name: "Preguntas", component: preguntas },
  { path: "/comisiones", name: "Comisiones ", component: comisiones },
  { path: "/oidc-callback", name: "Callback", component: Callback, meta: { isOidcCallback: true } },
  { path: "/Error", name: "Error", component: CallbackError, meta: { isPublic: true } },
  { path: "/Denegado", name: "Denegado", component: Denegado, meta: { isPublic: true } },
  { path: "*", name: "NotFound", component: NotFound, meta: { isPublic: true } },
];

export default routes;
