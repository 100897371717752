import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@/assets/site.css";
import { ToDate, ToMoney, ToDateTime, ToUpperSpace } from "@/filters";
import VueTheMask from "vue-the-mask";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.filter("toDate", ToDate);
Vue.filter("toDateTime", ToDateTime);
Vue.filter("toMoney", ToMoney);
Vue.filter("toUpperSpace", ToUpperSpace);
Vue.use(VueTheMask);
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCkF1DgAkR8NfB4ln-BCXcH4__B2v2laok",
    libraries: "places", // necessary for places input
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
