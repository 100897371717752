import Vue from "vue";
import { Kiosko } from "apd.sistemapagos.models";
import errorSnackbar from "@/store/modules/snackbarModule";
import { AxiosHttpClient, ApiSistemaPagos, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

export default Vue.extend({
  name: "TonelerosViewComponent",
  components: {
    toneleroCard: () => import("@/components/toneleroCard/toneleroCard.vue"),
  },
  data() {
    return {
      cajeros: [] as Kiosko[],
    };
  },
  created() {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
    );
    axiosHttpClient.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClient);

    apiSP
      .GetCajerosFisicos()
      .then((resp) => {
        this.cajeros = resp as Kiosko[];
      })
      .catch((resp) => {
        const x = resp as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        errorSnackbar.Show(x.apiError.mensajeUsuario);
      });
  },
});
