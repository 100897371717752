<template>
  <dashboard></dashboard>
</template>

<script>
import dashboard from "@/components/dashboard/dashboard.vue";

export default {
  name: "HomeComponent",
  components: {
    dashboard,
  },
};
</script>
