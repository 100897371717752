import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "../";
import { Kiosko } from "apd.sistemapagos.models";
import { Municipio, Estado } from "apd.geografia.models";
import ErrorSnackbarModule from "./snackbarModule";
import { AxiosHttpClient, ApiGeografia, ApiSistemaPagos, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

@Module({
  namespaced: true,
  name: "CajerosModule",
  store,
  dynamic: true,
})
class CajerosModule extends VuexModule {
  public cajeros: Kiosko[] = [];
  public lastCall: Kiosko[] = [];
  public ubicaciones: Municipio[] = [];
  public estados: Estado[] = [];
  public municipios: Municipio[] = [];

  public get CajeroById() {
    return (id: string): Kiosko => {
      return this.cajeros.find((o) => o.id == id) as Kiosko;
    };
  }

  @Mutation
  public SET_CAJEROS(data: Kiosko[]) {
    this.cajeros = this.cajeros.concat(data);
  }
  @Mutation
  public SET_MUNICIPIOS(data: Municipio[]) {
    this.municipios = data;
  }
  @Mutation
  public SET_LAST_CALL(data: Kiosko[]) {
    this.lastCall = data;
  }
  @Mutation SET_UBICACIONES(data: Municipio[]) {
    this.ubicaciones = data;
  }
  @Mutation
  public SET_ESTADOS(data: Estado[]) {
    this.estados = data;
  }

  @Action
  public async LoadUbicaciones() {
    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    //const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    /*let ids: number[] = [];

    try {
      ids = (await apiSP.GetUbicacionesCajeros()) as number[];
    } catch (err) {
      const x = err as ApiErrorResponse;
      x.apiError.mensajeUsuario;
      ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
    }

    const axiosHttpClientG: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.Geografia, this.context.rootGetters.appMode),
    );*/
    //axiosHttpClientG.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    //const apiGeo: ApiGeografia = new ApiGeografia(axiosHttpClientG);

    /*return apiGeo
      .ConsultarMunicipios(ids)
      .then((data) => {
        this.SET_UBICACIONES(data as Municipio[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });*/
  }
  @Action
  public async LoadEstados() {
    const axiosHttpClientG: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.Geografia, this.context.rootGetters.appMode),
    );
    axiosHttpClientG.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiGeo: ApiGeografia = new ApiGeografia(axiosHttpClientG);
    return apiGeo
      .GetEstados()
      .then((data) => {
        this.SET_ESTADOS(data as Estado[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadCajerosByMunicipio(id: number) {
    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    return apiSP
      .GetCajerosByMunicipio(id)
      .then((data) => {
        this.SET_LAST_CALL(data as Kiosko[]);
        this.SET_CAJEROS(data as Kiosko[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
  @Action
  public async LoadMunicipiosByEstado(id: number) {
    const axiosHttpClient: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.Geografia, this.context.rootGetters.appMode),
    );
    axiosHttpClient.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiGeo: ApiGeografia = new ApiGeografia(axiosHttpClient);

    return apiGeo
      .ConsultarMunicipiosEstado(id)
      .then((data) => {
        this.SET_MUNICIPIOS(data as Municipio[]);
      })
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }

  @Action
  public async activarEfectivo(idKiosko: string) {
    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    return apiSP
      .ActivarEfectivo(idKiosko) //llamar metodo activarEfectivo
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }

  @Action
  public async desactivarEfectivo(idKiosko: string) {
    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.context.rootGetters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.context.rootGetters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    return apiSP
      .DesactivarEfectivo(idKiosko) //llamar metodo activarEfectivo
      .catch((err) => {
        const x = err as ApiErrorResponse;
        x.apiError.mensajeUsuario;
        ErrorSnackbarModule.Show(x.apiError.mensajeUsuario);
      });
  }
}
export default getModule(CajerosModule);
