import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/src/locale/es";
import sat from "@/components/customIcons/sat.vue";
import banorte from "@/components/customIcons/banorte.vue";
import banorteDark from "@/components/customIcons/banorteDark.vue";
import visa from "@/components/customIcons/visa.vue";
import masterCard from "@/components/customIcons/masterCard.vue";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  icons: {
    values: {
      sat: {
        component: sat,
      },
      banorte: {
        component: banorte,
      },
      banorteDark: {
        component: banorteDark,
      },
      visa: {
        component: visa,
      },
      masterCard: {
        component: masterCard,
      },
    },
    iconfont: "mdiSvg",
  },
});
