import Vue from "vue";
import Componente from "@/models/componente";

export default Vue.extend({
  name: "DashboardComponent",
  components: {
    callCenterDash: () => import("@/components/dashboard/callCenterDash/callCenterDash.vue"),
    soporteDash: () => import("@/components/dashboard/soporteDash/soporteDash.vue"),
    adminDash: () => import("@/components/dashboard/adminDash/adminDash.vue"),
  },
  data() {
    return {
      dash: "" as string,
    };
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    user(): any {
      return this.$store.getters["oidcStore/oidcUser"];
    },
    multipleDash(): boolean {
      if (this.user !== null && this.user.SOSDashboard) {
        if (this.user.SOSDashboard.constructor === Array) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    dashList(): string[] {
      if (this.multipleDash) {
        return this.user.SOSDashboard;
      } else {
        return [this.user.SOSDashboard];
      }
    },
    componenteDash(): Componente {
      switch (this.dash) {
        case "CallCenter":
          return { name: "callCenterDash" };
        case "Soporte":
          return { name: "soporteDash" };
        case "Administracion":
          return { name: "adminDash" };
        default:
          return { name: "callCenterDash" };
      }
    },
  },
  mounted() {
    this.dash = this.dashList[0];
  },
});
