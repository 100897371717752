import Vue from "vue";
import cajerosModule from "@/store/modules/cajerosModule";
import { Kiosko } from "apd.sistemapagos.models";
import { Municipio } from "apd.geografia.models";
import { mdiMonitorDashboard, mdiGoogleMaps, mdiMonitorEdit, mdiPencil, mdiCheckBold } from "@mdi/js";
import dialogKioskos from "@/store/modules/nuevoKioskoModule";

interface Item {
  id: number;
  nombrePublico: string;
  children: Kiosko[];
}

export default Vue.extend({
  name: "CajerosViewComponent",
  components: {
    infoGen: () => import("@/components/cajero/infoGen/infoGen.vue"),
    configuracion: () => import("@/components/cajero/configuracion/configuracion.vue"),
    formaPago: () => import("@/components/cajero/formaPago/formaPago.vue"),
    permisos: () => import("@/components/cajero/permisos/permisos.vue"),
    ubicacion: () => import("@/components/cajero/ubicacion/ubicacion.vue"),
    nuevoKiosko: () => import("@/components/kioskos/nuevoKioskos/nuevoKiosko.vue"),
  },
  data() {
    return {
      active: [] as string[],
      open: [] as number[],
      icons: {
        ubicacion: mdiGoogleMaps,
        cajero: mdiMonitorDashboard,
        nuevo: mdiMonitorEdit,
        edit: mdiPencil,
        check: mdiCheckBold,
      } as Record<string, string>,
      items: [] as Item[],
      currentTab: 0,
      tab: null,
      tabs: ["Información General", "Configuración", "Forma de Pago", "Permisos", "Ubicación"],
      map: {},
      platform: {},
      e1: 1,
      nvoKiosko: false,
      edit: false,
      LeftDrawer: false as boolean,
      drawer: false,
      group: null,
    };
  },

  computed: {
    selected(): Kiosko | undefined {
      if (this.active.length <= 0) return undefined;

      const id = this.active[0];

      return cajerosModule.CajeroById(id) as Kiosko;
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },

  created() {
    cajerosModule.LoadUbicaciones().then(() => {
      const ubicaciones: Municipio[] = cajerosModule.ubicaciones;
      const data: Item[] = [];

      ubicaciones.forEach((item) => {
        data.push({
          id: item.id,
          nombrePublico: item.nombre + (item.estado == undefined ? "" : ", " + item.estado.abreviacion),
          children: [],
        });
      });

      this.items = data;
    });
  },

  methods: {
    async LoadCajeros(data: Item) {
      return cajerosModule.LoadCajerosByMunicipio(data.id).then(() => {
        data.children = cajerosModule.lastCall;
      });
    },

    isEdit() {
      this.edit = true;
    },

    nuevoKiosko() {
      dialogKioskos.Show();
    },

    btnCancel() {
      this.edit = false;
    },
  },
});
