import Vue from "vue";
import {
  mdiWeb,
  mdiMonitorDashboard,
  mdiMagnify,
  mdiPlusCircleOutline,
  mdiClose,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiCellphoneText,
} from "@mdi/js";
import preguntasModule from "@/store/modules/preguntasModule";
import { Articulo, Categoria } from "apd.sos.faq.models";
import spinnerModule from "@/store/modules/spinnerModule";

interface Item {
  id: number;
  titulo: string;
  children?: Articulo[];
  active?: boolean;
}
export default Vue.extend({
  name: "PreguntasViewComponent",
  components: {
    infoArticulo: () => import("@/components/articulos/infoArticulo/infoArticulo.vue"),
    nuevoArticulo: () => import("@/components/articulos/nuevoArticulo/nuevoArticulo.vue"),
  },
  data() {
    return {
      icons: {
        search: mdiMagnify,
        add: mdiPlusCircleOutline,
        cerrar: mdiClose,
        file: mdiFileDocumentOutline,
      } as Record<string, string>,
      search: null,
      dialog: false,
      articulo: {} as Articulo,
      categorias: [] as Item[],
      articulos: [] as Articulo[],
    };
  },
  computed: {
    empty(): boolean {
      return this.articulo != null && this.articulo.titulo != undefined ? false : true;
    },
    nombreCategoria() {
      return (value: number): string | undefined => {
        const nombre = preguntasModule.categorias.find((e) => e.id === value)?.nombre;
        return nombre;
      };
    },
    categoriaToIcon() {
      return (value: number): string => {
        switch (value) {
          case 1:
            return mdiCellphoneText;
          case 2:
            return mdiWeb;
          case 3:
            return mdiMonitorDashboard;
          case 4:
            return mdiFileDocument;
          default:
            return "";
        }
      };
    },
  },
  watch: {
    async search(val) {
      if (val != null && val != "") {
        await preguntasModule.LOAD_ARTICULOS_KEY(val).then(() => {
          this.articulos = preguntasModule.lastCall;
        });
      }
    },
  },
  async mounted() {
    spinnerModule.Show();
    await preguntasModule.LOAD_CATEGORIAS().then(() => {
      this.categorias = [];
      const categorias: Categoria[] = preguntasModule.categorias;
      const data: Item[] = [];
      categorias.forEach((item) => {
        data.push({
          id: item.id,
          titulo: item.nombre,
          children: [],
        });
      });
      data[0].active = false;
      this.categorias = data.sort((a, b) => (a.titulo < b.titulo ? -1 : 1));
    });
    spinnerModule.Hide();
  },
  methods: {
    async loadArticulos(data: Item) {
      if (!data.active) {
        spinnerModule.Show();
        await preguntasModule.LOAD_ARTICULOS_CATEGORIA(data.id);
      }
      spinnerModule.Hide();
      return (data.children = preguntasModule.lastCall.sort((a, b) => (a.titulo < b.titulo ? -1 : 1)));
    },
    selectArticulo(id: number) {
      this.$vuetify.goTo(0);
      const articulo = preguntasModule.ArticuloById(id);
      this.articulo = articulo;
      return articulo;
    },
  },
});
