import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "..";
import { ApiErrorResponse } from "axioshttpclient";

@Module({
  namespaced: true,
  name: "ErrorSnackbar",
  store,
  dynamic: true,
})
class SnackbarModule extends VuexModule {
  public errorMsg = "";
  public snackbar = false;
  public snackColor = "red";
  public LastError: ApiErrorResponse | null = null;

  get Color(): string | undefined {
    return this.snackColor;
  }

  get LastCallError(): boolean {
    if (this.LastError === null) {
      return false;
    } else {
      return true;
    }
  }
  get LastErrorMsg(): string {
    if (this.LastError != null) {
      return this.LastError.ApiError.mensajeUsuario;
    } else {
      return "";
    }
  }
  get LastErrorCode(): number {
    if (this.LastError != null) {
      return this.LastError.StatusCode;
    } else {
      return 0;
    }
  }

  @Mutation
  private SET_SNACKBAR(data: { value: boolean; msg: string; color: string }) {
    this.snackbar = data.value;
    this.errorMsg = data.msg;
    this.snackColor = data.color;
  }

  @Mutation
  public SET_ERROR(value: ApiErrorResponse) {
    this.LastError = value;
  }

  @Action
  public Show(msg: string) {
    this.SET_SNACKBAR({ value: true, msg, color: "red" });
  }

  @Action
  public ShowSuccess(msg: string) {
    this.SET_SNACKBAR({ value: true, msg, color: "green" });
  }

  @Action
  public Hide() {
    this.SET_SNACKBAR({ value: false, msg: "", color: "" });
  }
}
export default getModule(SnackbarModule);
