import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import onChangeRoute from "vuex-oidc-routermiddleware";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(onChangeRoute(store, "oidcStore", process.env.NODE_ENV === "production" ? "SOS" : "SOS.Test"));

export default router;
