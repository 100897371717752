import { format } from "date-fns";

export const ToDate = (value: string): string => {
  if (value) {
    return format(Date.parse(value), "dd/MM/yyyy");
  } else {
    return "";
  }
};

export const ToDateTime = (value: string): string => {
  if (value) {
    return format(Date.parse(value), "dd/MM/yyyy HH:mm:ss");
  } else {
    return "";
  }
};

export const ToMoney = (value: number | string): string => {
  if (typeof value !== "number") {
    if (typeof value === "string") {
      return "$" + value.replace("$", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
      return value;
    }
  }
  return "$" + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const ToUpperSpace = (value: string): string => {
  if (value) {
    value = value.toString().replace(/([a-z])([A-Z])/g, "$1 $2");
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return "";
  }
};
