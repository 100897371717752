import Vue from "vue";
import Accion from "@/models/accion";
import { mdiAccountSearch, mdiBug, mdiInformation, mdiCardSearch } from "@mdi/js";
import btnDashboard from "@/components/buttons/btnDashboard.vue";
import llamadaModule from "@/store/modules/llamadaModule";

export default Vue.extend({
  name: "SelectorAccionesComponent",
  components: {
    btnDashboard,
  },
  data() {
    return {
      acciones: [
        {
          id: 1,
          nombre: "Información",
          icon: mdiInformation,
          /*click: () => {
            llamadaModule.GoToStep(3);
          },*/
        },
        {
          id: 2,
          nombre: "Reporte de Fallos",
          icon: mdiBug,
          click: () => {
            llamadaModule.GoToStep(4);
          },
        },
        {
          id: 3,
          nombre: "Buscar Cliente",
          icon: mdiAccountSearch,
          click: () => {
            llamadaModule.GoToStep(5);
          },
        },
        {
          id: 4,
          nombre: "Buscar Operación",
          icon: mdiCardSearch,
          click: () => {
            llamadaModule.GoToStep(6);
          },
        },
      ] as Accion[],
    };
  },
  methods: {
    btnSiguienteClick() {
      llamadaModule.NextStep();
    },
  },
});
