const oidcSettings = {
  authority: "https://www.pagosdigitales.com/identityserver",
  clientId: "appSOS",
  redirectUri: "https://sos.pagosdigitales.com/oidc-callback/",
  responseType: "code",
  scope:
    "openid " +
    "profile " +
    "email " +
    "phone " +
    "APD.Profile " +
    "Api.SMS " +
    "Api.SistemaPagos.Full " +
    "Api.Documentos " +
    "Api.Gestopagos " +
    "Api.Bancos " +
    "APD.SOS " +
    "Api.Intranet " +
    "Api.Geografia " +
    "Api.MunicipioJuarez " +
    "Api.SOS.FAQ " +
    "Api.HEH",
  automaticSilentRenew: true,
  silentRedirectUri: "https://sos.pagosdigitales.com/silent-renew-oidc.html",
};

export default oidcSettings;
